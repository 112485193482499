<template>
  <div class="documents">
    <section>
      <div class="container">
        <div class="row">
          <div class="col-12 pt-5">
            <h2 class="text-center py-5  pt-md-0">Prochange Refund and Return Policy</h2>
            <h3 class="pb-3">1. Background</h3>
            <p class="pb-3">Prochange guarantees to any it’s User his/her right for refund, in case if such User is not
              satisfied with the quality of the provided Services. Furthermore, Prochange declares that its User has the
              right to change his/her mind in case if the User’s Account was already funded and to request his/her Funds
              to be returned.</p>
            <p class="pb-3">Every Refund and Return are treated by Prochange with reasonable care and skill.</p>
            <p class="pb-3">Note:</p>
            <ul>
              <li class="pb-3">This Refund Policy concerns exclusively Transaction fees and Users’ Funds.</li>
              <li class="pb-3">This Refund Policy does not cover any transactions between the Users related to purchase-sale of crypto-currencies and it does not refer to any refunds for purchased crypto-currencies.
              </li>
              <li class="pb-3">Prochange undertakes to make its best efforts to assist the Users in case of any disputes related to refunds of purchased crypto-currencies.
              </li>
              <li class="pb-3">Refunds and returns in excess of the original amount are prohibited.</li>
            </ul>
            <h3 class="pb-3">2. Definitions</h3>
            <ol>
              <li class="pb-3">
                <b>Banking Day:</b>
                is the business day of a bank. Banking days include all the days when offices of a bank are open for business to public. Usually banking day is all day except Saturday, Sunday and legally defined holidays.
              </li>
              <li class="pb-3"><b>Business Day: </b> means every official working day of the week in Lithuania.</li>
              <li class="pb-3"><b>Chargeback:</b> means return of the User's money by his/her Issuing Bank due to the disputed and/or fraudulent transaction.
              </li>
              <li class="pb-3"><b>Eligibility Criteria:</b>means the set of requirements that must be met for Refund or Return.
              </li>
              <li class="pb-3"><b>Return Form</b> means the Prochange form to be completed by the User, who has requested for his/her Funds to be returned.
              </li>
              <li class="pb-3"><b>Funds:</b> means a certain amount of money (Fiat currency and Cryptocurrency) transferred to and accumulated on the User’s Account.
              </li>
              <li class="pb-3"><b>Issuing Bank</b>  means the bank that has issued payment card to the User as Cardholder.
              </li>
              <li class="pb-3"><b>Refund original amount </b> means the precise amount of Transaction fee deducted from the amount of certain User’s Transaction.
              </li>
              <li class="pb-3"><b>Refund Form:</b> means the Prochange form to be completed by the User, who has requested the Refund.
              </li>
              <li class="pb-3"><b>Refund:</b>  means giving back the amount of Transaction fee paid by the User regarding certain Transaction.
              </li>
              <li class="pb-3"><b>Return original amount</b>  means the precise amount of the User’s Funds accumulated on his/her Account.
              </li>
              <li class="pb-3"><b>Return:</b>  means giving back the User’s Funds transferred to his/her Account.</li>
              <li class="pb-3"><b>Transaction Charges:</b> means any fee charged by a bank/financial institution upon processing a valid refund request.
              </li>
            </ol>
            <p class="pb-3">All other terms and definitions in this Refund Policy are used in the same meaning as under the Terms and Conditions.</p>
            <h3 class="pb-3">
              3. General Conditions
            </h3>
            <p class="pb-3">The User who has paid for the Prochange services, i.e., paid a transaction fee, or has funded his account may request a Refund or Return in accordance with the Eligibility Criteria as further set out herein.</p>
            <p class="pb-3">Refund/Return will only be considered where the User complies with the Eligibility Criteria in full. Where the User fails to meet any of the Eligibility Criteria, Prochange shall have the right, in its sole discretion, to decline the User’s request for a Refund/Return.</p>
            <p class="pb-3">
              In order to apply for a Refund/Return, the User must request and complete a Refund Form/Return Form and send the respective form to the Prochange support address.
            </p>

            <p class="pb-3">
              You can obtain the Refund/Return Form under request sent to <a href="mailto:support@exchanity.com">
               support@exchanity.com</a>
            </p>
            <p class="pb-3">To prevent Prohibited Conduct, all payments and information related to Refund/Return may be verified by Prochange. In such case, Prochange may request the User to provide certain documents, including, but not limited to, identification documents, copy of the User’s Payment Card and Invoice or/and any other prove of the fact that disputed payment was made. In case if the User fails to provide appropriate documents or information within three (3) days upon the Prochange request or in case of any doubts as to authenticity of provided documents, Prochange shall be entitled to decline the User’s Refund/Return request.</p>
            <p class="pb-3">Prochange shall process the User’s Refund Form/Return Form as soon as is reasonably practicable. Response times will vary depending on stated reasons for the request. In any case, Prochange shall notify the User on the outcome of the request in accordance with the timescales set out herein.</p>
            <p class="pb-3">
              Refund/Return request will only be approved or declined after meticulous verification made by Prochange.
            </p>
            <p class="pb-3">NOTE: Submission of Refund Form/Return Form does not guarantee that the User’s request will be satisfied.</p>
            <h3 class="pb-3">4. Refund Eligibility Criteria</h3>
            <ol>
              <li class="pb-3"> All requests for Refund will only be accepted where notice is received by Prochange no later than forty-eight (48) hours after made Transaction.
              </li>
              <li class="pb-3"> Notice mentioned in para (i) above should be provided to Prochange by completing Refund Form.
              </li>
              <li class="pb-3"> Completed Refund Form should be sent to the Prochange electronic address with ' Refund Request’ in the subject line.
              </li>
            </ol>

            <h3 class="pb-3">
              5. Return Eligibility Criteria
            </h3>

            <ol>
              <li class="pb-3"> All requests for Return should be provided to Prochange by completing Return Form.</li>
              <li class="pb-3"> Completed Return Form should be sent to the Prochange electronic address with 'Return Request’ in the subject line.
              </li>
              <li class="pb-3"> Prochange provides the Return using the same means of payment as the User used to pay, unless the User expressly agrees otherwise.
              </li>
            </ol>
            <h3 class="pb-3">6. Processing Timeline</h3>
            <p class="pb-3">
              Within fifteen (15) Business Days as of the date of Refund From/Return Form receipt, Prochange shall contact the User to request further information (if required) and the User shall provide such information as soon as reasonably practicable but in any case, no later than fourteen (14) days following such request.
            </p>
            <p class="pb-3">Prochange shall notify the User by e-mail on its final decision regarding Refund/Return:</p>
            <ul>
              <li class="pb-3">within fifteen (15) Business Days following the receipt of the last requested additional document or information.
              </li>
              <li class="pb-3">within twenty (20) Business Days following the receipt of Refund Form/Return Form if no further information or document is requested.
              </li>
            </ul>
            <p class="pb-3">Refund/Return will be processed without undue delay, and in any event within fourteen (14) days beginning with the day on which the Prochange agrees that the User is entitled to a Refund/Return.</p>
            <p class="pb-3">The User will have no Refund/Return:</p>
            <ul>
              <li class="pb-3">When requested by Prochange to do so, the User fails to provide Prochange with information or/and document(s) within the terms set herein.
              </li>
              <li class="pb-3">If Prochange suspects that the User has, or is, engaged in, or have in any way been involved in, fraudulent or illegal activity, including Prohibited Conduct.
              </li>
              <li class="pb-3">There is no ground for Refund/Return.</li>
            </ul>
            <p class="pb-3">NOTE: Prochange reserves the right to lock the User’s Funds while Refund/Return investigation is in process, which means that the Funds could be inaccessible during such investigation.</p>
            <h3 class="pb-3">7. Chargebacks</h3>
            <p class="pb-3">Prochange expects the User to contact it using Prochange contact details to resolve any problem or issue related to his/her payments before the User makes any Chargeback request. This Section does not affect any rights and/or claims, which the User may have against the bank/financial institution.</p>
            <p class="pb-3">Prochange will investigate any Chargeback requests made by the User and in response will inform the User’s Issuing Bank whether any Service or Transaction has been cancelled.</p>
            <p class="pb-3">Prochange reserves the right to suspend User's account and lock User's Funds during the chargeback investigation procedure.</p>
            <h3 class="pb-3">8. Miscellaneous</h3>
            <p class="pb-3">Any charges, which arise upon processing Refund/Return, shall be borne solely by the User. Such charges will be deducted from the final amount of Refund/Return.</p>
            <p class="pb-3">This Refund Policy will be amended from time to time if there is any change in the legislation. Terms and conditions of the Refund Policy are subject to change by Prochange and in the case of any amendments, Prochange will make an appropriate announcement. The changes will apply after Prochange has given notice.</p>
            <p class="pb-3">In case if the User does not wish to accept the revised Refund and Return Policy, he/she should not continue to use Prochange Services. If the User continues to use the Services after the date on which the change comes into effect, his/her use of the Services to be bound by the new Refund Policy and Return.</p>

          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "RefundAndReturn",
  mounted() {
    document.querySelector('body').scrollIntoView({
      behavior: 'smooth'
    });
  }
}
</script>
<style scoped>
li {
  margin-left: 30px;
}

</style>
